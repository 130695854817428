.nav{
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
}
.navhead{
    height: 50px;
    margin-left: 30px;
    margin-top: 0px;
    display: block;
}
.nav_fixed{
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}
.logo{
    height:50px;
    width: 224px;
    border-radius: 15;
}

.nav_comp{
    margin-left: auto;
    margin-right: 30px;
    font-size: 20px;
    font-weight: 500;
    color: black;
}
.nav_login{
    margin-right: 60px;
    font-size: 20px;
    font-weight: 500;
    background-color: #ef7e3d;
    border-color: #ef7e3d;
    color: #ffffff;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
    transition: width 0.3s;
    &:hover {
        background-color: #ffffff;
        color: #ef7e3d;
        border-color: #ef7e3d;
        border-width: 2.5px;
      }
}

.help{
    background-color: #f3f2f2;
    margin: 60px;
    height: auto;
    padding: 50px;
}
.quotation{
    font-size: 20px;
    font-weight: 600;
    display: block;
}

.quote{
    font-size: 20px;
    font-weight: 500;
    background-color: #ef7e3d;
    border-color: #ef7e3d;
    color: #ffffff;
    margin-left: 300px;
    margin-top: 310px;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
    transition: width 0.3s;
    &:hover {
        background-color: #ffffff;
        color: #ef7e3d;
        border-color: #ef7e3d;
        border-width: 2.5px;
      }
}
.tag_text{
    color: #f3894c;
    font-size: 26px;
    font-weight: 1000;
    font-family: 'poppins';
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 11px;
    margin-bottom: 30px;
}

.explore{
    text-align: center;
    color:#F58343;
    font-size: 50px;
    font-weight: 500;
    font-style: italic;
    margin-top: 120px;
}
.q{
    font-size: 20px;
    background-color: #ef7e3d;
    border: none;
    color: #ffffff;
    margin-left: 900px;
    padding-left: 40px;
    padding-right: 40px;
    transition: width 0.3s;
    &:hover {
        background-color: #ffffff;
        color: #ef7e3d;
        border-color: #ef7e3d;
        border-width: 2.5px;
      }
}

.footer{
    background-color: #003366;
    padding: 100px 0px 10px 150px;
    display: block;
}
.footertext{
    color: white;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 20px;
}