.bg_custom {
    background-color: #ea7d3e;
    border-radius: 0px 25px 25px 0px;
  }

.line{
    border-width: 3px;
    color: #ffffff;
}

.dashboard{
    height: auto;
    width: 75%;
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 5%;
    margin-left: 8%;
}

.profiledata{
    margin-top: 12%;
    margin-left: 2%;
}